import { useSecurePost } from '~/composables/dataFetching/genericFetchers';
import type { Result } from '~/server/api/[site]/user/resetPw.post';
import { handleLoadingError } from '~/utils/handleLoadingError';

export function useSendForgotPasswordSuccessView(email: string) {
  const site = useSiteIdent();
  const isLoading = ref(false);

  async function sendEmailAgin() {
    if (email) {
      try {
        isLoading.value = true;

        await useSecurePost<Result>(`/api/${site}/user/resetPw`, { email });

        isLoading.value = false;
      } catch (e) {
        isLoading.value = false;
        handleLoadingError(e);
      }
    }
  }

  return {
    isLoading,
    sendEmailAgin,
  };
}
